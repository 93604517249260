<template>
  <b-container v-can="'provider.create'">
    <b-row>
      <b-col lg="12">
        <div class="iq-edit-list-data">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Registro de Proveedores</h4>
            </template>
            <template v-slot:body>
              <createOrUpdate></createOrUpdate>
            </template>
          </iq-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import createOrUpdate from "./forms/createOrUpdate";

export default {
  name: "RegisterProvedores",
  components: {
    createOrUpdate,
  },
  mounted() {
    this.$store.commit("SET_BREADCRUMB", this.items);
  },
  data() {
    return {
      items: [
        {
          html: "Inicio",
          to: "/",
        },
        {
          text: "Listado de proveedores",
          to: "/provedores/list",
        },
        {
          text: "Registro de proveedores",
          active: true,
        },
      ],
    };
  },
};
</script>
<style>
.vs__clear {
  margin-right: 5px !important;
  margin-bottom: 3px !important;
}
</style>
